import React from "react";
import AiPower from "../../assets/Images/Homepage/HeroSection/Unio.svg";
import { Link } from "@reach/router";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";

function Leadership() {
  return (
    <div className="ai-power body-container leadership-wrap">
      <RevealAnimation animation="zoom-in" className="card-left-warp">
        <img src={AiPower} alt="" />
      </RevealAnimation>
      <div className="card-right-warp">
        <RevealAnimation component={"h2"} className="ai-world-card-title">
          British and Indian Leadership
        </RevealAnimation>
        <RevealAnimation
          component={"h3"}
          animation="fade"
          className="title-header title-underline"
        >
          Best of UK and <br /> Best of India
        </RevealAnimation>
        <RevealAnimation
          component={"p"}
          animation="fade"
          className="ai-subtitle"
        >
          Our management structure harmonises British organisational
          methodologies with world-class Indian engineering expertise, offering
          a comprehensive solution.
        </RevealAnimation>
        <RevealAnimation className="our-modal-card-btn">
          <Link className={`btnHero`} to={"/about-us"}>
            Learn more <KeyboardArrowRightOutlinedIcon />{" "}
          </Link>
        </RevealAnimation>
      </div>
    </div>
  );
}

export default Leadership;
