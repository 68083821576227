import React from "react";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";
function CTASection({ className = "", title, imageUrl }) {
  return (
    <div className={`ctaCard ${className}`}>
      <div className="cta-card-container body-container">
        <div className="ctaLeft">
          <RevealAnimation
            animation="fade"
            component={"h3"}
            className="title-header"
          >
            {title}
          </RevealAnimation>
        </div>
        <RevealAnimation
          component={"div"}
          animation="zoom-in"
          className="ctaCardImage"
        >
          <img src={imageUrl} alt="" />
        </RevealAnimation>
      </div>
    </div>
  );
}

export default CTASection;
