import React, { useState, useEffect } from "react";
import DataService from "../services/api/data_services";
import Layout from "../components/layouts/layout";
import LocationCards from "../templates/OurLocation";
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
import Miniview from "../templates/MiniView";
import Button from "../components/Button";
// import { initGA } from "../shared/ga-utils";
//cookie banner
// import { Cookies } from "react-cookie-consent";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";

import DigirytePods from "../assets/Images/Homepage/AgileServices/AgilePods.png";
import DigirytepodsHover from "../assets/Images/Homepage/AgileServices/AgilePodsHover.png";
import StaffAug from "../assets/Images/Homepage/AgileServices/StaffAug.png";
import StaffAughover from "../assets/Images/Homepage/AgileServices/StaffAughover.png";
import productdeve from "../assets/Images/Homepage/AgileServices/productdeve.png";
import ProductdeveHover from "../assets/Images/Homepage/AgileServices/ProductdeveHover.png";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import LeftGrid from "../templates/LeftGrid";
import MobileMovieSlider from "../components/MobileMovieSlider";
import { BASE_SITE_URL } from "../shared/constants";
//Image List
import ImageListDynamic from "../templates/ImageListDynamic";

//Box
import Box from "../templates/Box";

//Two column contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import CTAIMAGE1 from "../assets/Images/Homepage/HeroSection/Saly.svg";
import ContactImg from "../assets/Images/Homepage/HeroSection/contact.svg";

import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";

import awardsForMobile from "../assets/Images/About Us/awards____2x.png";
import ogimage from "../assets/Images/ogImages/og-digiryte.png";

import mPartnership from "../assets/Images/mobileView/OurPartnership.png";

import Partnership from "../assets/Images/Homepage/OurPartnership/Partnership.png";
import AccreditedSection from "../components/AccreditedSection";
import OurModelSection from "../components/OurModelSection";
import FlipCards from "../components/FlipCards";
import CTASection from "../components/CTASection";
import AIWorldEngineers from "../components/AIWorldEngineers/aiWorld";
import CtaCardSection from "../components/CTASection/ctaCard2";
import Leadership from "../components/AIWorldEngineers/Leadership";
import FormSection from "../components/FormSection";
import WhyDeveloper from "../components/WhyDevelopers";
import HeroSectionSlide from "../components/HeroSectionSlide";
import FullStackExpertise from "../components/FullStackExpertise";
import TrustedPartner from "../components/trustedPartner";
import OurServicesCard from "../components/OurServicesCard";
import RevealAnimation from "../components/RevealAnimation";

import HeroImage1Slide from "../assets/Images/Homepage/HeroSection/hero-illustration.png";
import HeroImage2Slide from "../assets/Images/Homepage/HeroSection/Frame-3.png";
import HeroImage3Slide from "../assets/Images/Homepage/HeroSection/Frame-1.png";
import LocationTab from "../components/LocationTab";

import WebLanguage1 from "../assets/Images/Homepage/weblang1.svg";
import WebLanguage2 from "../assets/Images/Homepage/weblang2.svg";
import AutoSlideImage from "../components/AutoSlideImage";
import NewHeroSection from "../components/HeroSection/newHeroSection";

const IndexPage = () => {
  const [awardsData, setAwardsData] = useState([]);
  const [page, setPage] = useState(1);
  const [awardsPage, setAwardsPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [awardRawData, setAwardRawData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [openUp, setOpen] = useState(false);

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      setOpen(true);
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 5);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/post?limit=1&page=1&page_id=1&type=White Paper"
    ).then((response) => {
      setMegaViewData(response);
    });
    DataService.getData(
      `/admin/api/awards?limit=${limit}&page=${awardsPage}&type=Awards`
    ).then((response) => {
      setAwardsData([...awardsData, ...response.data]);
      setAwardRawData(response);
      if (awardsPage === 1) {
        setAwardsPage(2);
      }
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=3&page=${page}&page_id=1&type=Article&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    DataService.getData(
      `/admin/api/post?limit=3&page=${page}&page_id=1&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  /**
   * Video Banner
   */

  // const VideobannerData = {
  //   color: "primary",
  //   bannerVideo: homePageVideo,
  //   videoPoster: VideoPoster,
  // };

  /**
   * Infoview
   */

  const InfoviewData = {
    // route: "/what-we-do",
    // color: "primary",
    // variant: "outlined",
    // tooltip: "yes",
    // width: "11%",
    // InfoviewButtonLabel: "LEARN MORE",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Our</span> Locations
      </div>
    ),
    InfoviewContent: (
      <>
        All our senior management team, tech consultants, client directors and
        account managers are all based in the UK. Our development team, which
        consists of the top 5% of the premium professionals make up our offshore
        talent. We ensure that all data for any project is stored within the UK
        borders only and access to development team is only on an on need basis.
      </>
    ),
  };
  /**
   * Infoview - Ask our clients
   */

  const InfoviewDataAskOurClients = {
    route: "/who-we-work-with",
    width: "112px",
    customClassName: "info-view-resize-btn",
    color: "primary",
    variant: "contained",
    tooltip: "no",
    tag: "h3",
    InfoviewButtonLabel: "Learn More",
    InfoviewTitle: (
      <div className="">
        See why our clients believe <br />{" "}
        <span className="red-line3letter red-3letter-mbl">we</span> are their
        ryte partners <br />{" "}
        {/* <span className="red-line3letter">Ask</span> Our Clients */}
      </div>
    ),
    InfoviewContent: (
      <>
        Our strong agile team empowers start-ups and entrepreneurs with
        customised solutions that transform their vision into great products for
        the market. With our extensive experience in software development,
        location-based mobile app development, and open-source products, we have
        helped hundreds of customers utilise technology and future-proof their
        business continuously. Find out how we are creating ground-breaking
        digital solutions and helping launch successful businesses across the
        UK.
      </>
    ),
  };

  // /**
  //  * Imagelist
  //  */

  // const ImagelistData = {
  //   ImageListTitle: (
  //     <div className="ttc">
  //       <span className="red-3letter-mbl">Awa</span>rds &<br />
  //       <span className="red-line3letter">Rec</span>ognitions
  //     </div>
  //   ),
  //   ImageListSubTitle: (
  //     <>
  //       Driven by an endless passion for technology, Digiryte is making progress
  //       in shaping the app economy. The awards that we have received are a
  //       testament to this.
  //     </>
  //   ),
  //   ImageListButtonColor: "primary",
  //   width: "113px",
  //   ImageListButtonLabel: "Find out More",
  //   awards: [
  //     {
  //       awardImage: awardsData.length > 0 ? awardsData[0].image :'',
  //       awardTitle: awardsData.length > 0 ? awardsData[0].title :'',
  //       awardSubTitle:awardsData.length > 0 ? awardsData[0].years :'',
  //       awardAlt: awardsData.length > 0 ? awardsData[0].imageAltTxt :'',
  //     },
  //     {
  //       awardImage: awardsData.length > 0 ? awardsData[1].image :'',
  //       awardTitle: awardsData.length > 0 ? awardsData[1].title :'',
  //       awardSubTitle:awardsData.length > 0 ? awardsData[1].years :'',
  //       awardAlt: awardsData.length > 0 ? awardsData[1].imageAltTxt :'',
  //     },
  //     {
  //       awardImage: awardsData.length > 0 ? awardsData[2].image :'',
  //       awardTitle: awardsData.length > 0 ? awardsData[2].title :'',
  //       awardSubTitle:awardsData.length > 0 ? awardsData[2].years :'',
  //       awardAlt: awardsData.length > 0 ? awardsData[2].imageAltTxt :'',
  //     },
  //     {
  //       awardImage: awardsData.length > 0 ? awardsData[3].image :'',
  //       awardTitle: awardsData.length > 0 ? awardsData[3].title :'',
  //       awardSubTitle:awardsData.length > 0 ? awardsData[3].years :'',
  //       awardAlt: awardsData.length > 0 ? awardsData[3].imageAltTxt :'',
  //     },
  //     {
  //       awardImage: awardsData.length > 0 ? awardsData[4].image :'',
  //       awardTitle: awardsData.length > 0 ? awardsData[4].title :'',
  //       awardSubTitle:awardsData.length > 0 ? awardsData[4].years :'',
  //       awardAlt: awardsData.length > 0 ? awardsData[4].imageAltTxt :'',
  //     },
  //   ],
  // };

  /**
   * Imagelist
   */

  const ImagelistData = {
    ImageListTitle: (
      <div className="ttc">
        <span className="red-line3letter">Awa</span>rds & recognitions
      </div>
    ),
    tag: "h4",
    ImageListSubTitle: (
      <>
        Driven by an endless passion for technology, Digiryte is making progress
        in shaping the app economy.
        <br /> The awards that we have received are a testament to this.
      </>
    ),
    ImageListButtonColor: "primary",
    ImageListButtonLabel: "Find out More",
  };

  /**
   * Box component
   */

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "Career",
    tip: "Career",
    tooltip: "no",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1Title: "Join our team",
    boxBtn1SubTitle: (
      <>
        If you're a designer or a developer <br /> who loves to challenge
        conventions,
        <br /> make the ryte move!
      </>
    ),
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "Call us right now",
    boxBtn2Title: "Let’s talk",
    tip1: "Call us right now",
    boxBtn2SubTitle: (
      <>
        Have you got an exciting project that <br /> you'd like to discuss?{" "}
      </>
    ),
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Blur Card List Data
   */

  // const BlurCardListData = {
  //   cardsData: [
  //     {
  //       image: MiniCardBgImage1,
  //       title: "Mobile Application Development",
  //       subTitle:
  //         "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/mobile-application-development",
  //     },
  //     {
  //       image: MiniCardBgImage2,
  //       title: "Web Application Development",
  //       subTitle:
  //         "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/web-application-development",
  //     },
  //     {
  //       image: MiniCardBgImage4,
  //       title: "MVP Development",
  //       subTitle:
  //         "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/mvp-development",
  //     },
  //     {
  //       image: MiniCardBgImage3,
  //       title: "Full Product Development",
  //       subTitle:
  //         "The process of full product development encompasses all steps needed to take your product from concept to market place.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/full-product-development",
  //     },
  //     {
  //       image: MiniCardBgImage5,
  //       title: "Product Designing and Prototyping",
  //       subTitle:
  //         "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/product-designing-and-prototyping",
  //     },
  //     {
  //       image: MiniCardBgImage6,
  //       title: "Product Scaling",
  //       subTitle:
  //         "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/product-scaling",
  //     },
  //     {
  //       image: MiniCardBgImage7,
  //       title: "Code & UX Audit",
  //       subTitle:
  //         "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/code-ux-audit",
  //     },
  //     {
  //       image: MiniCardBgImage8,
  //       title: "Digital Transformation",
  //       subTitle:
  //         "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/digital-transformation",
  //     },
  //     {
  //       image: MiniCardBgImage9,
  //       title: "Machine Learning",
  //       subTitle:
  //         "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
  //       customClass: "effect-btn no-padding-left mt-20",
  //       btnLabel: "Learn More",
  //       route: "/machine-learning",
  //     },
  //   ],
  // };

  //Movie Slider
  // const movieSlider = {
  //   imageUrl: clientMovieSliderImage,
  // };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };
  const mobMovieSlider1 = {
    imageUrl: mPartnership,
  };

  // const MobileBlurCard = {
  //   cardsData: [
  //     {
  //       image: Blr1,
  //       id: 11,
  //       title: "Startup",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/startups",
  //     },
  //     {
  //       image: Blr2,
  //       id: 12,
  //       title: "Healthcare",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/healthcare",
  //     },
  //   ],
  // };
  // const BlurCardListData12 = {
  //   cardsData: [
  //     {
  //       image: Blr1,
  //       title: "Startups",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/startups",
  //     },
  //     {
  //       image: Blr2,
  //       title: "Healthcare",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/healthcare",
  //     },
  //     {
  //       image: Blr3,
  //       title: (
  //         <>
  //           Retail and <br /> E-commerce
  //         </>
  //       ),
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/retail-and-e-commerce",
  //     },
  //     {
  //       image: Blr9,
  //       title: "Finance",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/finance",
  //     },
  //     {
  //       image: Blr8,
  //       title: "Education",
  //       button: "no",
  //       text: "white",
  //       cardHgt: "card-hgt",
  //       route: "/who-we-work-with/education",
  //     },
  //     // {
  //     //   image: Blr4,
  //     //   title: "Media and Publishing",
  //     //   button: "no",
  //     //   text: "white",
  //     //   cardHgt: "card-hgt",
  //     //   route: "/who-we-work-with/media-and-publishing",
  //     // },
  //     // {
  //     //   image: Blr5,
  //     //   title: "Hospitality",
  //     //   button: "no",
  //     //   text: "white",
  //     //   cardHgt: "card-hgt",
  //     //   route: "/who-we-work-with/hospitality",
  //     // },
  //     // {
  //     //   image: Blr10,
  //     //   title: "Real Estate and PropTech",
  //     //   button: "no",
  //     //   text: "white",
  //     //   cardHgt: "card-hgt",
  //     //   route: "/who-we-work-with/real-estate-and-proptech",
  //     // },
  //     // {
  //     //   image: Blr7,
  //     //   title: "Public Sector",
  //     //   button: "no",
  //     //   text: "white",
  //     //   cardHgt: "card-hgt",
  //     //   route: "/who-we-work-with/public-sector",
  //     // },
  //     // {
  //     //   image: Blr6,
  //     //   title: "Not-For-Profit",
  //     //   button: "no",
  //     //   text: "white",
  //     //   cardHgt: "card-hgt",
  //     //   route: "/who-we-work-with/not-for-profit",
  //     // },
  //   ],
  // };

  /**
   * Mini Image Banner 1
   */

  //  const MiniImageBanner1Data = {
  //   title: (
  //     <>
  //       Hire Remote  <br /> Teams
  //     </>
  //   ),
  //   titleClass: "mini-image-banner-title-area3 title-header2",
  //   image: MiniImageBannerBgImg,
  //   altTxt: "Who we work with",
  //   btnClassName: "image-banner-resize-btn",
  //   btnLabel: "TAKE A LOOK",
  //   tooltip: "yes",
  //   tip: "TAKE A LOOK",
  //   btnVariant: "contained",
  //   btnColor: "primary",
  //   route: "/hire-remote-teams",
  //   underline: true,
  //   underlineClass: "black-line",
  // };
  /**
   * Image Slider
   */
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Pune",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const imageData = {
    image1: StaffAug,
    imgHover1: StaffAughover,
    route1: "/team-augmentation",

    image2: DigirytePods,
    imgHover2: DigirytepodsHover,
    route2: "/digiryte-pods",

    image3: productdeve,
    imgHover3: ProductdeveHover,
    route3: "/product-development",
  };
  // const ImageSliderData = {
  //   ImageClass: "slider-img-short-logos whoweworkclients1",
  //   responsive: {
  //     0: { items: 2 },
  //     360: { items: 3 },
  //     600: { items: 2 },
  //     700: { items: 3 },
  //     1024: { items: 4 },
  //     1360: { items: 5 },
  //     1600: { items: 6 }
  //   },
  //   galleryItems: [
  //     {
  //       src: `${SliderImg1}`,
  //       alt: "MEHWISH"
  //     },
  //     {
  //       src: `${SliderImg2}`,
  //       alt: "CMS"
  //     },
  //     {
  //       src: `${SliderImg3}`,
  //       alt: "CrashVID"
  //     },
  //     {
  //       src: `${SliderImg4}`,
  //       alt: "DENTAL CARE"
  //     },
  //     {
  //       src: `${SliderImg5}`,
  //       alt: "DON"
  //     },
  //     {
  //       src: `${SliderImg6}`,
  //       alt: "Mic Computers"
  //     },
  //   ]
  // };
  const PlainCardWithHoverData = [
    {
      image: MiniCardBgImage3,
      title: "Team Augmentation",
      subTitle:
        "Highly skilled resources augmented into your existing in-house team to help increase the velocity, frequency and quality of your software delivery.",
      customClass: "effect-btn no-padding-left mt-20",
      // btnLabel: "Learn More",
      route: "/team-augmentation",
    },
  ];
  // const MediumviewData = {
  //   title: (
  //     <div className="ttc">
  //       <span className="red-line3letter red-3letter-mbl">Our</span> Engagement
  //       Models
  //     </div>
  //   ),
  //   underline: true,
  //   individualBoxData: {
  //     IndividualBoxTitle: "Explore synergies",
  //     IndividualBoxBtnLabel: "GET IN TOUCH",
  //     IndividualBoxBtnVariant: "contained",
  //     IndividualBoxBtnColor: "primary",
  //     route: "/contact-us",
  //   },
  //   data: [
  //     {
  //       MediumviewImage: MediumviewImage2,
  //       MediumviewAlt: "On-Shore Model",
  //       MediumviewHeading: "On-Shore Model",
  //       MediumviewContent: (
  //         <>
  //           Our On-Shore team is located in Manchester, UK, and will work
  //           closely with you to help with your product requirements. It is
  //           important to have seamless communication and therefore crucial that
  //           project managers work during the same hours that are well aligned
  //           with your availability. Our On-Shore Model implements tracking and
  //           reporting of work performance in line with the timelines of our
  //           UK-based clients to create an efficient work ecosystem.
  //           <br />
  //           <br />
  //           <b>Best Suited For</b>
  //           <br />
  //           <br />
  //           - UK’s Public Sector Enterprises or Business rooted in the UK who
  //           strictly want to work with a UK based team <br />- Businesses that
  //           require first-hand information on work progress and wants to
  //           interact continuously with the on-site team to quickly modify
  //           existing technology
  //         </>
  //       ),
  //       MediumviewButtonColor: "primary",
  //       MediumviewButtonLabel: "Find Out More",
  //       route: "/contact-us",
  //     },
  //     {
  //       MediumviewImage: MediumviewImage3,
  //       MediumviewAlt: "Off-Shore Model",
  //       MediumviewHeading: "Off-Shore Model",
  //       MediumviewContent: (
  //         <>
  //           Our offshore development center is based in India, and we ensure
  //           that all work meets the strict quality and safety standards set by
  //           the company. You can expect high quality work with our talented
  //           off-shore resources without being affected by time zone differences.
  //           Get access to the best technology and skilled personnel at an
  //           affordable cost.
  //           <br />
  //           You can be assured of the security of your data as it is stored in
  //           our UK servers. We use the latest technology tools for project
  //           management, reporting, and communication that ensure a smooth
  //           workflow for your project.
  //           <br /> <br />
  //           <b>Best Suited For</b>
  //           <br />
  //           <br />- Start-ups, Serial Innovators, or Ideapreneurs who have a
  //           limited budget but do not want to compromise on the quality of work.
  //         </>
  //       ),
  //       MediumviewButtonColor: "primary",
  //       MediumviewButtonLabel: "Find Out More",
  //       route: "/contact-us",
  //     },
  //     {
  //       MediumviewImage: MediumviewImage1,
  //       MediumviewAlt: "Dual-Shore Model",
  //       MediumviewHeading: "Dual-Shore Model",
  //       MediumviewContent: (
  //         <>
  //           Get the best of both worlds with our Dual-Shore Model. Get access to
  //           our team in the UK and India as per your project requirements.
  //           Adopting the Dual Shore Model comes down to matching the right
  //           people with the right tasks. It is very important to find a team
  //           that is well-aligned with the type of project requirements. With the
  //           Dual-Shore Model, we offer you a dynamic range of offshore and
  //           onshore service options that can scale and adapt to your core
  //           business needs.
  //           <br />
  //           <br />
  //           <b>Best Suited For</b>
  //           <br />
  //           <br />- Startups, Small and Medium-Sized Enterprises (SME’s), or
  //           Large Enterprises who want to scale-up with the high technical
  //           competence at an affordable rate of the offshore model as well as
  //           the benefits of in-house expertise of the onshore model.
  //         </>
  //       ),
  //       MediumviewButtonColor: "primary",
  //       MediumviewButtonLabel: "Find Out More",
  //       route: "/contact-us",
  //     },
  //   ],
  // };

  // const handleAcceptCookie = () => {
  //   if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  //     initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  //   }
  // };

  // const handleDeclineCookie = () => {
  //   //remove google analytics cookies
  //   Cookies.remove("_ga");
  //   Cookies.remove("_gat");
  //   Cookies.remove("_gid");
  // };

  // useEffect(() => {
  //   const isConsent = getCookieConsentValue();
  //   if (isConsent === "true") {
  //     handleAcceptCookie();
  //   }
  // }, []);

  const listObjectSlideData = [
    {
      title1: "Ryte way",
      title2: "to go offshore",
      sup: "TM",
      subTitle: "",
      buttonName: "Contact Us",
      buttonUrl: "/contact-us",
      imageUrl: HeroImage1Slide,
      items: [
        "Build your own team from 4 to 100+ software engineers",
        "Delivering India's top 3% tech talent from the UK",
        "Powered by Industry-specific software engineers",
      ],
    },
    {
      title1: "UK’s No.1",
      title2: "Custom Software Development Company",
      // subTitle: "by Clutch from 2019 to 2024",
      sup: "",
      buttonName: "Contact Us",
      buttonUrl: "/contact-us",
      imageUrl: HeroImage3Slide,
      items: ["Build New Product", "Enhance Existing Product", "24/7 Support"],
    },
    {
      title1: "We help IT Leaders",
      title2: "meet their Delivery Goals",
      subTitle: "",
      sup: "",
      buttonName: "Reach Out Now!",
      buttonUrl: "/contact-us",
      imageUrl: HeroImage2Slide,
      items: ["Build New Product", "Enhance Existing Product", "24/7 Support"],
      customSlideClass: "customSlide",
    },
  ];

  return (
    <Layout
      seo={{
        title: `Digiryte: Developing and Delivering SaaS, Digital Products and Platforms`,
        ogtitle:
          "Developing and Delivering SaaS, Digital Products and Platforms | Digiryte",
        description:
          "Partner with us to build SaaS Product, apps, APIs, websites, cloud and data platforms. Overcome your digital skills gap with the help of our expert team.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
        keywords:
          "digital product development, custom software solutions, app development services, SaaS product development, tech consultancy services",
      }}
    >
      {/* <HeroSection /> */}
      {/* <HeroSectionSlide listObjectData={listObjectSlideData} /> */}
      <NewHeroSection />
      <AccreditedSection />
      <FullStackExpertise />
      <div>
        <div className="body-container ">
          <RevealAnimation
            component={"div"}
            animation={"slide-left"}
            className="title-header title-underline"
          >
            Languages We Speak!
          </RevealAnimation>
          <div className="mt-30">
            <AutoSlideImage imageUrl={WebLanguage1} direction="left-to-right" />
            <AutoSlideImage imageUrl={WebLanguage2} direction="right-to-left" />
          </div>
        </div>
      </div>

      <div className=" mt-70 ">
        <TrustedPartner
          title={<>Trusted Partner for Startups, Scaleups and Enterprises</>}
          showAllBtn={true}
          imgContainerClassName="custom-gap"
        />
      </div>
      <OurModelSection />

      <div className="mt-70 ">
        <CTASection
          className="common-o-bg"
          title={
            <>
              Collaborate seamlessly
              <br /> with your offshore team,
              <br /> mirroring your on-site operations
            </>
          }
          imageUrl={CTAIMAGE1}
        />
      </div>
      <FlipCards />
      <div className=" mt-70 ">
        <WhyDeveloper
          title={
            <>
              Why We Select Only The Top 3% of <br />
              Developers For You
            </>
          }
        />
      </div>
      {/* <AIWorldEngineers
        title="Emerging Silicon Valley of the AI World"
        subTitle=" Top-tier software engineers in India"
        description="India hosts the R&D hubs of Fortune 500 and industry giants like
          Google, Amazon, and Microsoft. Digiryte gives direct access to a vast
          pool of exceptionally skilled professionals, ready to be onboarded for
          you."
        btnLink="/india/"
      /> */}
      {/* <div className="">
        <CtaCardSection
          imageUrl={ContactImg}
          title={
            <>
              {" "}
              <span className="animationTitle">Contact Us </span>
               To Accelerate your Digital Delivery
            </>
          }
          className="common-o-bg"
        />
      </div> */}

      <div className="mt-70">
        <Leadership />
      </div>
      <div
        style={{ backgroundColor: "#fff3f2", padding: "30px 0" }}
        className=" mt-70 "
      >
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Our </span>
            Engagement Models
          </RevealAnimation>
        </div>

        <div className="mt-70 mb-20">
          <OurServicesCard />
        </div>
      </div>
      {/* <div className="mt-70">
        <LocationTab />
      </div> */}
      <div>
        <div className="body-container mt-40 mb-10">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Testimonials from delighted clients
          </RevealAnimation>
          <div className="mt-70">
            <div
              class="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="4"
              data-height="auto"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-reviews="325818,288702,283278,266050,165609"
              data-clutchcompany-id="86428"
            ></div>
          </div>
        </div>
      </div>
      <div className="common-bg-light mt-70 ">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Fea</span>tured
            Insights
          </RevealAnimation>
          <div className="mt-50"></div>
          <Megaview MegaviewData={megaviewData.data} />
        </div>
        {/* )} */}
        {articleData && articleData.length > 0 && (
          <div className="mt-70">
            <Miniview miniviewData={articleData} />
          </div>
        )}
        {articleRawData.has_more && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              className="mt-60"
              role="presentation"
              style={{ display: "inline-block" }}
            >
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="MORE ARTICLES"
                  />
                }
                customClassName="info-view-resize-btn bg-white"
                color="primary"
                variant="outlined"
                label="Read more"
                routepath="/insights/articles"
                tip="Read More"
                tooltip="no"
              />
            </div>
          </div>
        )}
      </div>
      <div className=" mt-70">
        <RevealAnimation animation="fade" className="">
          <Infoview InfoviewData={InfoviewDataAskOurClients} />
        </RevealAnimation>

        {clientStoryData && clientStoryData.length > 0 && (
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              navigateComponent={<LeftGrid />}
            />
          </div>
        )}
        {clientStoryRawData.has_more && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              className="mt-60"
              role="presentation"
              style={{ display: "inline-block" }}
            >
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="Load More"
                  />
                }
                customClassName="info-view-resize-btn bg-white"
                color="primary"
                variant="outlined"
                routepath="/who-we-work-with"
                label="More Client Stories"
                tip="More Client Stories"
                tooltip="no"
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-70 common-bg-light">
        <FormSection />
      </div>
      {/* <div className=" mt-70">
        <RevealAnimation animation="zoom-in" className="">
          <Infoview InfoviewData={InfoviewData} />
        </RevealAnimation>
        <div className="hide-in-mobile">
          <LocationCards
            mapImage={mapImage}
            label="View Location"
            LocationCardData={LocationCardData}
          />
        </div>
        <div className="hide-in-web">
          <LocationCards
            mapImage={mapImage1}
            label="View Location"
            LocationCardData={LocationCardData1}
          />
        </div>
      </div> */}
      <div className="body-container mt-40">
        <RevealAnimation
          animation="slide-left"
          component={"h3"}
          className="title-header "
        >
          <span className="red-line3letter red-3letter-mbl">Pro</span>ud
          partners of
        </RevealAnimation>
      </div>
      <RevealAnimation animation="fade" className="mt-50 body-container">
        <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />

        <img
          className="hide-in-mobile"
          style={{ width: "100%" }}
          src={Partnership}
          alt="Partnership"
        />
      </RevealAnimation>
      <div className="common-bg-light mt-70">
        <div>
          <RevealAnimation animation="fade" className="hide-in-mobile mb-20">
            <ImageListDynamic
              ImagelistData={awardsData.length > 0 ? ImagelistData : ""}
              awardsData={awardsData}
            />
          </RevealAnimation>
        </div>

        <div className="hide-in-web body-container">
          <h3 className="title-header ">Awards & Recognitions</h3>
        </div>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      <div className="">
        <Box BoxData={BoxData} />
      </div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default IndexPage;
